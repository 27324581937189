import React from 'react';
import request from 'superagent';
import Markdown from 'react-remarkable';
import Loader from '../common/Loader';

const researcherGithubUrls = [
  'https://api.github.com/repos/pollfish/docs/contents/terms/researcher.md',
  'https://api.github.com/repos/pollfish/docs/contents/terms/researcher-link.md',
];

const gitHubUrls = {
  account: researcherGithubUrls,
  researcher: researcherGithubUrls,
  publisher: 'https://api.github.com/repos/pollfish/docs/contents/terms/publisher.md',
};

const researcherTerms = 'https://www.pollfish.com/terms/researcher';

const pollfishUrls = {
  publisher: 'https://www.pollfish.com/terms/publisher',
  researcher: researcherTerms,
  account: researcherTerms,
};

class GdprContent extends React.Component {

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      termsContent: '',
      loadingConsent: true,
    }
  }

  componentDidMount() {
    const { type } = this.props;

    if (type === 'publisher') {
      request
        .get(gitHubUrls[type])
        .set('Accept', 'application/vnd.github.VERSION.raw')
        .end((err, res) => {
          if (res.statusCode === 200) {
            this.setState({ termsContent: res.text });
          } else {
            console.log(`Could not fetch ${type} terms`);
          }
          this.setState({ loadingConsent: false });
        });
    } else {
      Promise.all(gitHubUrls[type].map((termsUrl) =>
        request
          .get(termsUrl)
          .set('Accept', 'application/vnd.github.VERSION.raw')))
        .then((values) => {
          const termsContent = values.reduce((acc, current) => {
            acc += `${current.text}\n`;
            return acc;
          }, '');

          this.setState({
            termsContent,
            loadingConsent: false,
          });
        }).catch((error) => {
            console.log(`Could not fetch ${type} terms`);
            console.error(error);
            this.setState({
              loadingConsent: false,
            });
      });
    }
  }

  render() {
    const {
      termsContent,
      loadingConsent,
    } = this.state;

    const { type } = this.props;
    const pollfishTermsUrl = pollfishUrls[type];

    if (loadingConsent) {
      return <div className="gdpr__consent-loader"><Loader /></div>;
    }

    if (termsContent === '') {
      return (
        <div className={`gdpr__${type === 'publisher' ? 'publisher' : 'researcher'}-consent`}>
          <p>Please visit the following link to review the {type} terms.</p>
          <a href={pollfishTermsUrl} target="_blank">{pollfishTermsUrl}</a>
        </div>
      );
    }
    if (termsContent !== '') {
      return (
        <div className={`gdpr__${type === 'publisher' ? 'publisher' : 'researcher'}-consent`}>
          <Markdown source={termsContent} />
        </div>
      );
    }
  }
};

export default GdprContent;
