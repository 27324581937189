export const ONBOARDING_STEPS_VALUES = {
  PASSWORD: "password",
  FULL_NAME: "fullName",
  COMPANY_NAME: "companyName",
  QUALIFICATION: "qualification",
  QUALIFICATION_WITH_INFO: "qualification_with_info",
  REFERRAL: "referral",
  REFERRAL_OTHER: "otherReferral",
  LEGAL: "consent",
  NEWSLETTER: "Newsletter",
};

export const ONBOARDING_STEPS = [
  ONBOARDING_STEPS_VALUES.PASSWORD,
  ONBOARDING_STEPS_VALUES.FULL_NAME,
  ONBOARDING_STEPS_VALUES.COMPANY_NAME,
  ONBOARDING_STEPS_VALUES.QUALIFICATION,
  ONBOARDING_STEPS_VALUES.REFERRAL,
  ONBOARDING_STEPS_VALUES.LEGAL,
];

export const ONBOARDING_STEPS_GOOGLE = [
  ONBOARDING_STEPS_VALUES.FULL_NAME,
  ONBOARDING_STEPS_VALUES.COMPANY_NAME,
  ONBOARDING_STEPS_VALUES.QUALIFICATION,
  ONBOARDING_STEPS_VALUES.REFERRAL,
  ONBOARDING_STEPS_VALUES.LEGAL,
];

export const QUALIFICATION_OPTIONS_REPORT = {
  2: "create_survey",
  3: "get_paid",
  4: "own_list",
};

export const QUALIFICATION_OPTIONS = {
  CREATE_SURVEY: "2",
  GET_PAID: "3",
  OWN_LIST: "4",
};

export const PUBLIC_EMAIL_DOMAINS = [
  "aol.com",
  "att.net",
  "comcast.net",
  "email.com",
  "facebook.com",
  "fastmail.fm",
  "games.com",
  "gmail.com",
  "gmx.com",
  "gmx.net",
  "google.com",
  "googlemail.com",
  "hotmail.co.uk",
  "hotmail.com",
  "hush.com",
  "hushmail.com",
  "icloud.com",
  "iname.com",
  "inbox.com",
  "lavabit.com",
  "live.com",
  "love.com",
  "mac.com",
  "mail.com",
  "mail.ru",
  "me.com",
  "msn.com",
  "outlook.com",
  "pobox.com",
  "protonmail.com",
  "qq.com",
  "rocketmail.com",
  "safe-mail.net",
  "sbcglobal.net",
  "verizon.net",
  "wow.com",
  "yahoo.co.uk",
  "yahoo.com",
  "yandex.com",
  "ygm.com",
  "ymail.com",
  "zoho.com",
];

export const PASSWORD_REQUIREMENTS_KEYS = {
  LENGTH: "length",
  LOWERCASE: "lowerCase",
  UPPERCASE: "upperCase",
  NUMBER: "number",
};

export const PASSWORD_REQUIREMENTS_LIST = [
  PASSWORD_REQUIREMENTS_KEYS.LOWERCASE,
  PASSWORD_REQUIREMENTS_KEYS.UPPERCASE,
  PASSWORD_REQUIREMENTS_KEYS.NUMBER,
  PASSWORD_REQUIREMENTS_KEYS.LENGTH,
];

export const PASSWORD_DESCRIPTIONS = {
  [PASSWORD_REQUIREMENTS_KEYS.LENGTH]: "At least 8 characters in length",
  [PASSWORD_REQUIREMENTS_KEYS.LOWERCASE]: "At least one lowercase letter",
  [PASSWORD_REQUIREMENTS_KEYS.UPPERCASE]: "At least one uppercase letter",
  [PASSWORD_REQUIREMENTS_KEYS.NUMBER]: "At least one digit",
};

export const PASSWORD_VALIDATION_MESSAGES = {
  [PASSWORD_REQUIREMENTS_KEYS.LENGTH]:
    "Password must be at least 8 characters in length",
  [PASSWORD_REQUIREMENTS_KEYS.LOWERCASE]:
    "Password must contain at least one lowercase letter",
  [PASSWORD_REQUIREMENTS_KEYS.UPPERCASE]:
    "Password must contain at least one uppercase letter",
  [PASSWORD_REQUIREMENTS_KEYS.NUMBER]:
    "Password must contain at least one number",
};
