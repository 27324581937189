import { createStore } from 'reflux';
import signupActions from './signup_actions';
import loginActions from '../login/login_actions';
import request from 'superagent';

const signupStore = createStore({

  listenables: [signupActions],

  onSignup(data, accountHash, loginMethod = 'google') {

    request
      .post('/api/v3/user/register')
      .query({
        accountHash: accountHash
      })
      .set('Accept', 'application/json')
      .send(data)
      .end((err, res) => {
        if(err) {
          return this.trigger({
            fail: true,
            errorStatus: err.status,
            errorData: err.response.body
          });
        }

        if(res.statusCode === 200 || res.statusCode === 204) {
          loginActions.analytics(res.body, null, loginMethod);
          this.trigger({ loggedIn: true });
        }
      })
  },
});

export default signupStore;
