import React, { useState, useCallback } from "react";
import request from "superagent";
import { DEFAULT_ERRORS } from "../../common/constants";
import {
  ONBOARDING_STEPS_VALUES,
  PASSWORD_REQUIREMENTS_LIST,
  PASSWORD_DESCRIPTIONS,
} from "../constants";
import messages from "../messages";
import { pressedEnter } from "../../common/utils";
import BackButton from "./backButton";

const StepPassword = ({
  currentStep,
  value,
  error,
  handleChange,
  handleSubmit,
  backAction,
  updateValue,
}) => {
  const [loadingPasswordCheck, setLoadingPasswordCheck] = useState(false);
  const [passwordWarningBackend, setWarningBackend] = useState("");
  const [isPasswordVisible, setPasswordVisibility] = useState(false);

  const onSubmit = useCallback(
    (e) => {
      setLoadingPasswordCheck(true);
      request
        .post("/api/v3/user/password/check")
        .set("Accept", "application/json")
        .send({ password: value })
        .end((err, res) => {
          setLoadingPasswordCheck(false);
          if (err) {
            setWarningBackend(
              "There was something wrong with the server. Please try again"
            );
            return;
          }
          const data = (res && res.body) || {};
          if (data.score > 1) {
            handleSubmit(e);
          } else {
            updateValue(currentStep, "");
            setWarningBackend(
              data.warning || DEFAULT_ERRORS.POLLFISH_PASSWORD_WEAK
            );
          }
        });
    },
    [handleSubmit, value, setLoadingPasswordCheck]
  );

  const togglePasswordVisibility = useCallback(() => {
    setPasswordVisibility(!isPasswordVisible);
  }, [isPasswordVisible, setPasswordVisibility]);

  if (currentStep !== ONBOARDING_STEPS_VALUES.PASSWORD) {
    return null;
  }

  return (
    <div>
      {backAction && <BackButton onClick={backAction} />}
      <h1 className="main-panel__title text-align-left">
        {messages.onboardingHeading}
      </h1>
      <h3 className="main-panel__subtitle text-align-left">
        {messages[ONBOARDING_STEPS_VALUES.PASSWORD]}
        <sup>*</sup>
      </h3>
      <div className="pf-form pf-form--onboard">
        <div className="pf-form__textfield">
          <div className="flex items-center relative">
            <input
              className="pf-form__textfield__input"
              name="password"
              tabIndex="1"
              autoFocus={true}
              value={value}
              onChange={handleChange}
              onKeyPress={(e) => {
                if (pressedEnter(e)) {
                  onSubmit(e);
                }
              }}
              type={isPasswordVisible ? "text" : "password"}
              placeholder="8+ characters"
              data-testid='password-input'
            />
            <button
              type="button"
              className="absolute right-5 border-none padding-none margin-none bg-transparent"
              onClick={togglePasswordVisibility}
            >
              {!isPasswordVisible && <i className="icon-eye" />}
              {isPasswordVisible && <i className="icon-eye-off" />}
            </button>
          </div>
          <div className="pf-form__message pf-form__message--error">
            {error}
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#eef7f4",
            padding: "10px 16px",
            margin: "0 0 20px",
            borderRadius: "4px",
            color: "#586c86",
          }}
        >
          <h5>Password requirements:</h5>
          <ul className="password-requirements-list">
            {PASSWORD_REQUIREMENTS_LIST.map((key) => (
              <li className="password-requirements-list-item" key={key}>
                {PASSWORD_DESCRIPTIONS[key]}
              </li>
            ))}
          </ul>
          {passwordWarningBackend && (
            <div className="pf-form__message pf-form__message--error">
              {passwordWarningBackend}
            </div>
          )}
        </div>
        <div className="pf-form__textfield">
          <button
            data-testid='password-next-button'
            className={`pf-form__textfield-button full-width pf-form__textfield-button--submit`}
            tabIndex="4"
            type="button"
            onClick={onSubmit}
            disabled={loadingPasswordCheck}
          >
            {messages.next}
          </button>
        </div>
      </div>
    </div>
  );
};

export default StepPassword;
