export const DEFAULT_ERRORS = {
  email: "Please enter a valid email address",
  fullName: "Please enter your name (characters .:@/ are not allowed)",
  companyName: "Please enter your company's name",
  password: "Your password must be at least 8 characters long",
  referral: "Please select an option",
  qualification: "Please select an option",
  legal:
    "Please accept the Terms, Data Policy and Cookies Policy to create your account",
  name: "Please enter your full name<br/>(characters .:/@ are not allowed)",
  genericError: "Something went wrong. Please refresh your page and try again",
  service_unavailable:
    "The service is temporarily unavailable. Please try again later",
  no_gdpr_consent: "You haven't accepted the latest terms and conditions",
  POLLFISH_EMAIL_INVALID: "The e-mail address you entered is invalid",
  POLLFISH_REGISTRATION_FORM_INVALID: "The form is incomplete",
  POLLFISH_USERNAME_OR_PASSWORD_INVALID:
    "Oops! That email / password combination is not valid",
  POLLFISH_EMAIL_EXISTS: "An account with this e-mail address already exists",
  POLLFISH_NO_SELECTED_ACCOUNT_EXISTS:
    "Oops! Your are not assigned to any account. Please contact support",
  POLLFISH_PASSWORD_WEAK: "You need to add a stronger password",
  SSO_FAIL:
    "There was an error while trying to login using Single Sign On. Please contact support",
};

export const ERROR_CODES = {
  POLLFISH_PASSWORD_WEAK: "POLLFISH_PASSWORD_WEAK",
};

export const EMAIL_VERIFY_ERROR_CODES = [
  "email_disabled",
  "dead_server",
  "invalid_mx",
  "disposable",
  "spamtrap",
];

export const REGISTER_PEEQ_PARAM = "peeqRegister";
