import React from 'react';


const Logo = (props) => {
  const className = props.className || '';
  return (
    <div className={`logo ${className}`}>
      <a href='/'>
        <i className="icon-pollfish_logo_full" />
      </a>
    </div>
  );
}

export default Logo;
