import React from "react";
import PropTypes from "prop-types";
import request from "superagent";
import Link from "react-router/lib/Link";
import get from "lodash/get";
import Logo from "../common/Logo";
import Copyright from "../common/copyright";
import { REGISTER_PEEQ_PARAM } from "../common/constants";

import onboardingActions from "./onboarding_actions";
import onboardingStore from "./onboarding_store";
import { getSearchParameters, isInPeeqRegisterFlow } from "../common/utils";
import loginStore from "../login/login_store";
import { DEFAULT_ERRORS } from "../common/constants";
import {
  ONBOARDING_STEPS,
  ONBOARDING_STEPS_GOOGLE,
  ONBOARDING_STEPS_VALUES,
  QUALIFICATION_OPTIONS,
} from "./constants";
import messages from "./messages";
import StepPassword from "./steps/password";
import StepFullName from "./steps/fullName";
import StepCompanyName from "./steps/companyName";
import StepQualification from "./steps/qualification";
import StepReferral from "./steps/referral";
import StepLegal from "./steps/legal";

const SidebarStep = (props) => {
  const { disabled, isActive, text, onClick } = props;

  return (
    <li
      className={`onboarding__step margin-btm-sm ${
        isActive ? "onboarding__step--active" : ""
      }`}
    >
      <button
        type="button"
        className={`onboard__step-btn ${
          !disabled || isActive ? "font-bold" : ""
        }`}
        disabled={!isActive && disabled}
        onClick={onClick}
      >
        {text}
      </button>
    </li>
  );
};

class Onboarding extends React.Component {
  static propTypes = {
    params: PropTypes.object,
    location: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState() {
    const fields = getSearchParameters();
    const {
      fullName,
      companyName,
      companyEmail,
      qualification,
      referral,
      otherReferral,
      email,
      token,
      accountHash,
      referralResearcherToken,
    } = fields;
    const steps = this.isGoogleRegistration(token)
      ? ONBOARDING_STEPS_GOOGLE
      : ONBOARDING_STEPS;
    fields.steps = steps;
    const currentStep = this.isGoogleRegistration(token)
      ? ONBOARDING_STEPS_VALUES.FULL_NAME
      : ONBOARDING_STEPS_VALUES.PASSWORD;
    const activeSteps = this.getStepState(steps, fields);
    return {
      peeqRegister: false,
      values: {
        email,
        token,
        accountHash,
        referralResearcherToken,
        password: "",
        fullName: fullName || "",
        companyName: companyName || "",
        companyEmail: companyEmail || "",
        qualification: qualification || "",
        referral: referral || "",
        otherReferral: otherReferral || "",
        legal: null,
        newsletter: false,
      },
      isSubmitting: false,
      steps,
      loginMethod: this.isGoogleRegistration(token) ? "google" : "pollfish",
      consentTypes: ["GDPR"],
      currentStep,
      activeSteps,
      errors: {
        password: null,
        fullName: null,
        companyName: null,
        companyEmail: null,
        qualification: null,
        referral: null,
        otherReferral: null,
        legal: null,
        wrong: "",
      },
      autocompleteSuggestions: [],
    };
  }

  isGoogleRegistration = (token) => !!token;

  isValidField = (fieldName, fields) => {
    switch (fieldName) {
      case ONBOARDING_STEPS_VALUES.PASSWORD:
        return typeof fields[fieldName] === "string"
          ? fields[fieldName].length >= 4
          : false;
      case ONBOARDING_STEPS_VALUES.COMPANY_NAME:
      case ONBOARDING_STEPS_VALUES.QUALIFICATION_WITH_INFO:
        return true;
      case ONBOARDING_STEPS_VALUES.FULL_NAME:
        return typeof fields[fieldName] === "string"
          ? /^[^.:/@]+$/.test(fields[fieldName])
          : false;
      default:
        return !!fields[fieldName];
    }
  };

  getStepState = (steps, currentStep) => {
    let foundActive = false;
    let activeSteps = {};

    const currentStepIndex = steps.indexOf(currentStep);

    steps.forEach((step, index) => {
      if (!foundActive) {
        activeSteps[step] = index < currentStepIndex;

        if (!activeSteps[step]) {
          foundActive = true;
          currentStep = step;
        }
      } else {
        activeSteps[step] = false;
      }
    });
    return activeSteps;
  };

  redirectToSignup() {
    window.location.assign("/signup/researcher");
  }

  componentWillMount() {
    const { location } = this.props;
    const peeqRegister = isInPeeqRegisterFlow(location, true);

    if (peeqRegister && !this.state.peeqRegister) {
      this.setState({
        peeqRegister: true,
      });
    }

    if (!this.state.values.email && !this.state.values.token) {
      this.redirectToSignup();
    }
    this.unsubscribeOnboardingStore = onboardingStore.listen(
      this.onDataChange.bind(this)
    );
    this.unsubscribeLoginStore = loginStore.listen(
      this.onDataChange.bind(this)
    );
  }

  componentWillUnmount() {
    this.unsubscribeOnboardingStore();
    this.unsubscribeLoginStore();
    this.removeScrollEventListener();
  }

  addScrollEventListener = () =>
    window.addEventListener("scroll", this.hideAutocompleteSuggestions, true);

  removeScrollEventListener = () =>
    window.removeEventListener(
      "scroll",
      this.hideAutocompleteSuggestions,
      true
    );

  handleMenuVisibilityChange = (isOpen) => {
    if (isOpen) {
      this.addScrollEventListener();
    } else {
      this.removeScrollEventListener();
    }
  };

  onDataChange(data) {
    if (data.loggedIn && !data.isGuest) {
      const { redirect_url } = this.props.location.query;
      if (redirect_url) {
        window.location = `${window.location.protocol}//${window.location.host}${redirect_url}`;
        return false;
      } else {
        window.location.assign("/dashboard/surveys");
      }
    }

    if (data.fail) {
      let { errors } = this.state;
      let { errorData } = data;
      errors.wrong = DEFAULT_ERRORS.genericError;
      if (data.errorStatus === 400) {
        errors.wrong =
          errorData && errorData.errors
            ? DEFAULT_ERRORS[errorData.errors[0]] ||
              DEFAULT_ERRORS.POLLFISH_USERNAME_OR_PASSWORD_INVALID
            : DEFAULT_ERRORS.POLLFISH_USERNAME_OR_PASSWORD_INVALID;
      }
      if (data.errorStatus === 503) {
        errors.wrong = DEFAULT_ERRORS.service_unavailable;
      }
      this.setState({ errors, currentStep: ONBOARDING_STEPS_VALUES.PASSWORD });
    } else {
      let { errors } = this.state;
      errors.wrong = "";
      this.setState({ errors });
    }
  }

  handleChange = (e) => {
    e.persist();
    this.setState((state) => ({
      values: {
        ...state.values,
        [e.target.name]: e.target.value,
      },
    }));
  };

  updateValue = (name, value) => {
    this.setState((state) => ({
      values: {
        ...state.values,
        [name]: value,
      },
    }));
  };

  handleCheckboxChange(e) {
    e.persist();
    this.setState((state) => ({
      values: {
        ...state.values,
        [e.target.name]: e.target.checked,
      },
    }));
  }

  handleSelectChange(option) {
    this.setState((state) => ({
      values: {
        ...state.values,
        referral: option,
      },
    }));
  }

  handleAutoCompleteItem(companyName) {
    this.setState((state) => ({
      values: {
        ...state.values,
        companyName,
      },
      autocompleteSuggestions: [],
    }));
  }

  handleDeclineTerms = () => {
    this.setState((state) => ({
      errors: {
        ...state.errors,
        legal: DEFAULT_ERRORS.legal,
      },
    }));
  };

  handleChangeCompanyName = (event) => {
    const value = event.target.value;
    this.setState((state) => ({
      values: {
        ...state.values,
        companyName: value,
      },
    }));
    if (value) {
      request
        .get(
          `https://autocomplete.clearbit.com/v1/companies/suggest?query=${value}`
        )
        .set("Accept", "application/json")
        .end((err, res) => {
          if (res && res.body) {
            this.setState({ autocompleteSuggestions: res.body });
          } else {
            this.setState({ autocompleteSuggestions: [] });
          }
        });
    } else {
      this.setState({ autocompleteSuggestions: [] });
    }
  };

  register() {
    //prepare object for api call
    const { consentTypes, loginMethod } = this.state;
    const { newsletter } = this.state.values;
    let { referral } = this.state.values;
    referral = referral.value;
    if (newsletter) {
      consentTypes.push("Newsletter");
    }
    let data = {
      ...this.state.values,
      consentTypes,
      referral,
    };
    data.isDeveloper = false;
    data[REGISTER_PEEQ_PARAM] = this.state.peeqRegister;

    const registerData = {
      data,
      userType: "researcher",
      accountHash: data.accountHash,
      referralResearcherToken: data.referralResearcherToken,
      loginMethod,
    };

    onboardingActions.register(registerData);
    this.setState({
      isSubmitting: true,
    });
  }

  goToPrevStep = (currentStep) => {
    const activeSteps = this.getStepState(this.state.steps, currentStep);
    this.setState({
      currentStep,
      activeSteps,
    });
  };

  goToStep = ({ field, step }) => {
    if (this.isValidField(field, this.state.values)) {
      let currentStep = step;
      if (field === ONBOARDING_STEPS_VALUES.QUALIFICATION) {
        if (this.state.values.qualification === QUALIFICATION_OPTIONS.GET_PAID)
          currentStep = ONBOARDING_STEPS_VALUES.QUALIFICATION_WITH_INFO;
      }
      const activeSteps = this.getStepState(this.state.steps, currentStep);

      this.setState({
        currentStep,
        errors: {
          [field]: null,
        },
        activeSteps,
      });
    } else {
      this.setState({
        errors: {
          [field]: DEFAULT_ERRORS[field],
        },
      });
    }
  };

  handleSubmit(event) {
    event.preventDefault();
    this.register();
  }

  handleRadioChange = (e) => {
    const qualification = e.target.value;
    return this.setState((state) => {
      return {
        values: {
          ...state.values,
          qualification,
        },
      };
    });
  };

  goToSignup = () => {
    const { location } = this.props;
    const inPeeqRegisterFlow = isInPeeqRegisterFlow(location, true);
    if (inPeeqRegisterFlow) {
      this.props.router.push("/signup/account");
    } else {
      this.props.router.push("/signup/researcher");
    }
  };

  renderSidebarSteps = () => {
    return (
      <ul className="onboard__step-list">
        <li className="onboarding__step onboarding__step--new-account margin-btm-sm ">
          <button
            type="button"
            className="onboard__step-btn font-bold"
            onClick={this.goToSignup}
          >
            {messages.newAccount}
          </button>
        </li>
        {this.state.steps.map((step) => {
          return (
            <SidebarStep
              key={step}
              disabled={!this.state.activeSteps[step]}
              isActive={this.state.currentStep === step}
              text={messages[step]}
              onClick={() => this.goToPrevStep(step)}
            />
          );
        })}
      </ul>
    );
  };

  renderErrorMessage() {
    return (
      this.state.errors.wrong && (
        <div className="pf-form__message pf-form__message--error pf-form__message--padding">
          {this.state.errors.wrong}
        </div>
      )
    );
  }

  isCurrentStepLegal() {
    return this.state.currentStep === ONBOARDING_STEPS_VALUES.LEGAL;
  }

  isCurrentStepQualification() {
    return this.state.currentStep === ONBOARDING_STEPS_VALUES.QUALIFICATION;
  }

  render() {
    const { currentStep } = this.state;
    return (
      <div className="onboard">
        <Logo className="logo--fixed" />
        <div className="onboard-sidebar">
          {this.renderSidebarSteps()}
          <div className="decorative-triangle decorative-triangle--4"></div>
        </div>
        <div className="onboard-content">
          <header className="panel-header">
            <div className="switch-action">
              <div className="switch-action__label">Already a user?</div>
              <Link
                className="pf-btn pf-btn--inline switch-action__btn pf-btn--bordered"
                to={`/login/${this.props.params.userType}`}
              >
                Log in
              </Link>
            </div>
          </header>
          <form
            className={`onboard-content__inner padding-left-sm padding-right-sm ${
              this.isCurrentStepLegal() || this.isCurrentStepQualification()
                ? "onboard-content__inner--long margin-btm-xl"
                : ""
            }`}
            onSubmit={(e) => e.preventDefault()}
          >
            {!this.state.values.token && (
              <StepPassword
                currentStep={currentStep}
                backAction={(e) => {
                  e.preventDefault();
                  this.goToSignup();
                }}
                givenEmail={this.state.values.email}
                value={this.state.values.password}
                error={this.state.errors.password}
                handleChange={(e) => this.handleChange(e)}
                updateValue={this.updateValue}
                handleSubmit={(e) => {
                  e.preventDefault();
                  this.goToStep({
                    field: ONBOARDING_STEPS_VALUES.PASSWORD,
                    step: ONBOARDING_STEPS_VALUES.FULL_NAME,
                  });
                }}
              />
            )}
            <StepFullName
              currentStep={currentStep}
              value={this.state.values.fullName}
              error={this.state.errors.fullName}
              handleChange={(e) => this.handleChange(e)}
              backAction={
                !this.isGoogleRegistration(this.state.values.token)
                  ? (e) => {
                      e.preventDefault();
                      this.goToPrevStep(ONBOARDING_STEPS_VALUES.PASSWORD);
                    }
                  : (e) => {
                      e.preventDefault();
                      this.goToSignup();
                    }
              }
              handleSubmit={(e) => {
                e.preventDefault();
                this.goToStep({
                  field: ONBOARDING_STEPS_VALUES.FULL_NAME,
                  step: ONBOARDING_STEPS_VALUES.COMPANY_NAME,
                });
              }}
            />
            <StepCompanyName
              currentStep={currentStep}
              value={this.state.values.companyName}
              error={this.state.errors.companyName}
              autocompleteSuggestions={this.state.autocompleteSuggestions}
              handleChange={(val) => this.handleChangeCompanyName(val)}
              onMenuVisibilityChange={this.handleMenuVisibilityChange}
              handleSelectChange={(val) => this.handleAutoCompleteItem(val)}
              backAction={(e) => {
                e.preventDefault();
                this.goToPrevStep(ONBOARDING_STEPS_VALUES.FULL_NAME);
              }}
              handleSubmit={(e) => {
                if (e) {
                  e.preventDefault();
                }
                this.goToStep({
                  field: ONBOARDING_STEPS_VALUES.COMPANY_NAME,
                  step: ONBOARDING_STEPS_VALUES.QUALIFICATION,
                });
              }}
            />
            <StepQualification
              currentStep={currentStep}
              value={this.state.values.qualification}
              error={this.state.errors.qualification}
              handleChange={(e) => this.handleSelectChange(e)}
              handleRadioChange={(e) => this.handleRadioChange(e)}
              backAction={(e) => {
                e.preventDefault();
                this.goToPrevStep(ONBOARDING_STEPS_VALUES.COMPANY_NAME);
              }}
              handleSubmit={(e) => {
                e.preventDefault();
                this.goToStep({
                  field: ONBOARDING_STEPS_VALUES.QUALIFICATION,
                  step: ONBOARDING_STEPS_VALUES.REFERRAL,
                });
              }}
            />
            <StepReferral
              currentStep={currentStep}
              value={this.state.values.referral}
              otherValue={this.state.otherReferral}
              error={this.state.errors.referral}
              handleSelectChange={(e) => this.handleSelectChange(e)}
              handleChange={(e) => this.handleChange(e)}
              backAction={(e) => {
                e.preventDefault();
                this.goToPrevStep(ONBOARDING_STEPS_VALUES.QUALIFICATION);
              }}
              handleSubmit={(e) => {
                e.preventDefault();
                this.goToStep({
                  field: ONBOARDING_STEPS_VALUES.REFERRAL,
                  step: ONBOARDING_STEPS_VALUES.LEGAL,
                });
              }}
            />
            <StepLegal
              currentStep={currentStep}
              error={this.state.errors.legal}
              value={this.state.values.newsletter}
              disabled={this.state.isSubmitting}
              handleChange={(e) => this.handleCheckboxChange(e)}
              handleDeclineTerms={(e) => this.handleDeclineTerms(e)}
              handleSubmit={(e) => this.handleSubmit(e)}
              backAction={(e) => {
                e.preventDefault();
                this.goToPrevStep(ONBOARDING_STEPS_VALUES.QUALIFICATION);
              }}
              errorMessage={this.renderErrorMessage()}
            />
            <Copyright />
          </form>
          <div className="decorative-triangle decorative-triangle--1"></div>
        </div>
      </div>
    );
  }
}

export default Onboarding;
