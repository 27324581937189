import React from "react";
import PropTypes from "prop-types";
import Google from "react-google-login";
import { isInPeeqRegisterFlow } from "../utils";
import loginActions from "../../login/login_actions";
import loginStore from "../../login/login_store";
import { REGISTER_PEEQ_PARAM } from "../../common/constants";
import googleIcon from "../../../images/google.svg";

const googleStyle = {
  display: "block",
  background: "none",
  color: "inherit",
  padding: 0,
  borderRadius: 0,
  border: 0,
  fontSize: "inherit",
  fontWeight: "normal",
  width: "100%",
};

class GoogleLogin extends React.Component {
  static propTypes = {
    params: PropTypes.object,
    location: PropTypes.object,
    gdprData: PropTypes.object,
    type: PropTypes.oneOf(["login", "signup"]),
    button: PropTypes.element,
    accountHash: PropTypes.string,
    referralResearcherToken: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      peeqRegister: false,
    };
  }

  componentWillMount() {
    this.unsubscribe = loginStore.listen(this.onDataChange.bind(this));
    const { location } = this.props;
    const peeqRegister = isInPeeqRegisterFlow(location);

    if (peeqRegister && !this.state.peeqRegister) {
      this.setState({
        peeqRegister: true,
      });
    }
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  onDataChange(data) {
    if (data.loggedIn && !data.isGuest) {
      const { redirect_url } = this.props.location.query;
      if (redirect_url) {
        window.location = `${window.location.protocol}//${window.location.host}${redirect_url}`;
        return false;
      }

      const location = !data.isPublisher
        ? "/dashboard/surveys"
        : "/dashboard/dev/";
      window.location = location;
    }
  }

  getLabel() {
    return this.props.type === "login"
      ? "Log in with Google"
      : "Register with Google";
  }

  responseGoogle(response) {
    const { peeqRegister } = this.state;
    loginActions.google({
      data: response,
      role: this.props.params.userType,
      body: this.props.gdprData,
      [REGISTER_PEEQ_PARAM]: peeqRegister,
      accountHash: this.props.accountHash,
      referralResearcherToken: this.props.referralResearcherToken,
    });
  }

  responseGoogleFail(response) {
    console.log(response);
  }

  renderButton() {
    return (
      <div className="pf-btn pf-btn--google">
        <img
          src={googleIcon}
          className="pf-btn__icon"
          title="Google"
          alt="Google"
        />
        {this.getLabel()}
      </div>
    );
  }

  render() {
    return (
      <Google
        //the commented id is for testing. Check readme
        // clientId="830075405711-ruo9hcgh3jt5tb0cef298jjsbbaabr8s.apps.googleusercontent.com"
        clientId="830075405711.apps.googleusercontent.com"
        style={googleStyle}
        buttonText={this.props.button || this.renderButton()}
        onSuccess={this.responseGoogle.bind(this)}
        onFailure={this.responseGoogleFail}
        disabled={this.props.disabled}
      />
    );
  }
}

export default GoogleLogin;
