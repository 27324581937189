import React from 'react';
import Autocomplete from 'react-autocomplete';
import { ONBOARDING_STEPS_VALUES } from '../constants';
import messages from '../messages';
import BackButton from './backButton';
import { pressedEnter } from '../../common/utils';

class StepCompanyName extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidUpdate() {
    this.refs.companyName && this.refs.companyName.focus();
  }

  render() {
    const {
      currentStep,
      value,
      error,
      handleChange,
      handleSelectChange,
      handleSubmit,
      autocompleteSuggestions,
      onMenuVisibilityChange,
      backAction
    } = this.props;
    if (currentStep !== ONBOARDING_STEPS_VALUES.COMPANY_NAME) {
      return null;
    }

    return (
      <div>
        <div className="text-align-left">
          {backAction && (
            <BackButton onClick={backAction} />
          )}
          <h1 className="main-panel__title">{messages.generalHeading}</h1>
          <h3 className="main-panel__subtitle">{messages[ONBOARDING_STEPS_VALUES.COMPANY_NAME]}</h3>
        </div>
        <div className="pf-form pf-form--onboard">
          <div className="pf-form__textfield">
            <Autocomplete
              wrapperStyle={{ display: 'block'}}
              renderMenu={(items, value, style) => <div style={{ ...style, zIndex: '1', border : 'solid 1px #c6c7c9' }} children={items}/>}
              name={ONBOARDING_STEPS_VALUES.COMPANY_NAME}
              getItemValue={(item) => item.name}
              items={autocompleteSuggestions}
              focus={true}
              ref="companyName"
              renderItem={(item, isHighlighted) =>
                (<div key={item.domain} className="autocomplete_item" style={{ background: isHighlighted ? '#e0e6ed' : 'white' }}>
                  <span>{item.name}</span>
                  <img role="presentation" src={item.logo} />
                </div>)
              }
              inputProps={{
                className: "pf-form__textfield__input",
                placeholder: "e.g. Pollfish",
                tabIndex: "2",
                onKeyPress: (e) => {
                  if (pressedEnter(e)) {
                    handleSubmit();
                  }
                },
              }}
              value={value}
              onChange={handleChange}
              onSelect={handleSelectChange}
              onMenuVisibilityChange={onMenuVisibilityChange}
              data-testid='company-name-input'
            />
            <div className="pf-form__message pf-form__message--error">{ error }</div>
          </div>
          <div className="pf-form__textfield">
            <button
              data-testid='company-name-next-button'
              className={`pf-form__textfield-button full-width pf-form__textfield-button--submit`}
              tabIndex="4"
              type="button"
              onClick={handleSubmit}
            >
              {messages.next}
            </button>
          </div>
        </div>
      </div>
    );
  }

}


export default StepCompanyName;
