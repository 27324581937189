import { createStore } from 'reflux';
import onboardingActions from './onboarding_actions';
import loginActions from '../login/login_actions';
import { QUALIFICATION_OPTIONS_REPORT, PUBLIC_EMAIL_DOMAINS } from './constants';
import request from 'superagent';

const trackQualification = ({ qualification }) => {
  if(window.analytics) {
    window.analytics.track('f_qualification', {
      label: qualification,
    });
  }
};

const trackNonPublicDomain = (email) => {
  //called after registration success so no check for valid argument/email regex
  const emailParts = email.split('@');
  const emailDomain = emailParts[emailParts.length - 1];
  if(!PUBLIC_EMAIL_DOMAINS.includes(emailDomain) && window.analytics) {
    window.analytics.track('registered.custom.domain', {
      emailDomain,
    });
  }
};

const onboardingStore = createStore({

  listenables: [onboardingActions],

  onFetch() {
    request
      .get('/api/v2/user')
      .end((err, res) => {
        if(err) {
          return false;
        }
        if(res.statusCode === 200) {
          this.trackAnalytics(res.body);
          this.trigger({ loggedIn: true, isPublisher: !!res.body.publisher });
        }
      });
  },

  onRegister({data, accountHash, loginMethod = 'google'}) {
    request
      .post('/api/v3/user/register')
      .query({
        accountHash: accountHash
      })
      .set('Accept', 'application/json')
      .send(data)
      .end((err, res) => {
        if(err) {
          return this.trigger({
            fail: true,
            errorStatus: err.status,
            errorData: err.response.body
          });
        }

        if(res.statusCode === 200 || res.statusCode === 204) {
          trackNonPublicDomain(res.body.email);
          trackQualification({
            email: res.body.email,
            qualification: QUALIFICATION_OPTIONS_REPORT[data.qualification],
          });
          
          loginActions.analytics(res.body, null, loginMethod);
          this.trigger({ loggedIn: true });
        }
      })
  },
});

export default onboardingStore;
