import React, { Fragment } from "react";
import PropTypes, { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import { Base64 } from "js-base64";
import shuffle from "lodash/shuffle";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import Loader from "../common/Loader";
import Gdpr from "../common/gdpr";
import { DEFAULT_ERRORS, ERROR_CODES } from "../common/constants";
import SwitchUser from "../common/switch_user";
import signupActions from "./signup_actions";
import signupStore from "./signup_store";
import loginStore from "../login/login_store";
import {
  isEmailValid,
  getSearchParameters,
  isInPeeqRegisterFlow,
  isEmailStatusValid,
} from "../common/utils";
import GoogleLogin from "../common/google_login/google_login";
import { REGISTER_PEEQ_PARAM } from "../common/constants";
import SingleSignOn from "../common/SingleSignOn";
import loginActions from "../login/login_actions";

const bitLabsSignupUrl =
  "https://dashboard.bitlabs.ai/auth/signup?prodege_partner_refer=pollfish";

const referralOptionsTemplate = [
  {
    value: 0,
    text: "Advertisement",
  },
  {
    value: 1,
    text: "Conference",
  },
  {
    value: 2,
    text: "Email / Inmail",
  },
  {
    value: 3,
    text: "Friend / Colleague",
  },
  {
    value: 4,
    text: "Google",
  },
  {
    value: 6,
    text: "Podcast",
  },
  {
    value: 7,
    text: "Video",
  },
  {
    value: 8,
    text: "MediaPost",
  },
  {
    value: 5, //Other's value is 5. Do not change
    text: "Other",
  },
];

class Signup extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
    params: PropTypes.object,
    location: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      peeqRegister: false,
      autocompleteSuggestions: [],
      referralOptions: shuffle(referralOptionsTemplate),
      errors: {
        email: "",
        password: "",
        name: "",
        companyName: "",
        referral: "",
        otherReferral: "",
        errorMessage: "",
      },
      values: {
        name: "",
        email: "",
        companyName: "",
        referral: null,
        otherReferral: "",
        password: "",
      },
      gdpr: {
        modal: false,
        data: {
          consentTypes: ["GDPR", "Newsletter"],
        },
        isLandingRef: false,
      },
      isPasswordVisible: false,
      loginMethod: null,
    };
  }

  componentWillMount() {
    let state = {
      ...this.state,
    };
    const { cookies, location } = this.props;

    const peeqRegister = isInPeeqRegisterFlow(location);

    loginActions.fetch();
    this.unsubscribe = loginStore.listen(this.onDataChange.bind(this));

    const userType = this.props.params.userType;
    if (
      userType !== "researcher" &&
      userType !== "publisher" &&
      userType !== "account"
    ) {
      window.location = "/signup/researcher";
    }

    const { name, email } = getSearchParameters();

    if (peeqRegister && !this.state.peeqRegister) {
      this.setState({
        peeqRegister: true,
      });
    }

    if (cookies.get("consent")) {
      state = {
        ...state,
        values: {
          name,
          email,
        },
        gdpr: {
          ...this.state.gdpr,
          data: JSON.parse(Base64.decode(cookies.get("consent"))),
          isLandingRef: cookies.get("consent") ? true : false,
        },
      };

      this.setState((prevState) => {
        return {
          ...prevState,
          ...state,
        };
      });

      this.openGdprModal();
      this.removeConsentCookie();
    }

    this.unsubscribeSignupStore = signupStore.listen(
      this.onDataChange.bind(this)
    );
    this.unsubscribeLoginStore = loginStore.listen(
      this.onDataChange.bind(this)
    );
  }

  componentWillUnmount() {
    this.unsubscribeSignupStore();
    this.unsubscribeLoginStore();
    this.removeScrollEventListener();
  }

  goToOnboarding() {
    const { peeqRegister } = this.state;
    const { accountHash, referralResearcherToken } = getSearchParameters();
    let url = `/onboarding?email=${this.state.values.email}`;
    if (accountHash) {
      url += `&accountHash=${accountHash}`;
    }
    if (referralResearcherToken) {
      url += `&referralResearcherToken=${referralResearcherToken}`;
    }
    if (peeqRegister) {
      url += `&${REGISTER_PEEQ_PARAM}=true`;
    }
    this.props.router.push(url);
  }

  onDataChange(data) {
    if (
      this.state.is_submitting &&
      data.emailExists &&
      this.props.params.userType !== "publisher"
    ) {
      this.setState({
        is_submitting: false,
        errors: {
          errorMessage: DEFAULT_ERRORS.POLLFISH_EMAIL_EXISTS,
        },
      });
      return;
    }

    const isEmailValid = isEmailStatusValid(data.verifyResponse);

    if (
      this.state.is_submitting &&
      !isEmailValid &&
      this.props.params.userType !== "publisher"
    ) {
      this.setState({
        is_submitting: false,
        errors: {
          errorMessage: DEFAULT_ERRORS.POLLFISH_EMAIL_INVALID,
        },
      });
      return;
    }
    if (
      this.state.is_submitting &&
      !data.emailExists &&
      isEmailValid &&
      this.props.params.userType !== "publisher" &&
      !this.state.errors.email
    ) {
      this.setState({
        is_submitting: false,
      });
      this.goToOnboarding();
    }
    this.removeConsentCookie();
    const { peeqRegister } = this.state;
    const role = data.role ? data.role : this.props.params.userType;

    if (data.token) {
      const { accountHash, referralResearcherToken } = getSearchParameters();
      let url = `/onboarding?token=${data.token}`;
      if (data.fullName) {
        url += `&fullName=${data.fullName}`;
      }
      if (accountHash) {
        url = `${url}&accountHash=${accountHash}`;
      }
      if (referralResearcherToken) {
        url = `${url}&referralResearcherToken=${referralResearcherToken}`;
      }
      if (peeqRegister) {
        url = `${url}&${REGISTER_PEEQ_PARAM}=true`;
      }
      this.props.router.push(url);
      return;
    }

    if (data.loggedIn && !data.isGuest) {
      const redirectURL =
        get(this.state, "gdpr.data.redirectUrl", undefined) ||
        get(this.props, "location.query.redirect_url", undefined);

      if (redirectURL) {
        window.location = `${window.location.protocol}//${window.location.host}${redirectURL}`;
        return false;
      }

      // no redirectURL exists
      if (role === "publisher") {
        window.location.assign("/dashboard/dev");
      } else {
        window.location.assign("/dashboard/surveys");
      }
    }

    if (data.fail) {
      this.onClose(true);
      if (data.errorStatus === 451) {
        this.setState({
          gdpr: {
            modal: true,
            data: data.errorData,
            isLandingRef: true,
          },
        });
      } else {
        this.setState({
          gdpr: {
            modal: false,
            data: data.errorData,
          },
          is_submitting: false,
        });
      }

      let { errors } = this.state;
      const { errorStatus, errorData, ssoFailed } = data;
      errors.errorMessage =
        DEFAULT_ERRORS.POLLFISH_USERNAME_OR_PASSWORD_INVALID;
      if (errorStatus === 503) {
        errors.errorMessage = DEFAULT_ERRORS.service_unavailable;
      }

      if (errorStatus === 400) {
        const errorCode = get(errorData, "errors[0]");
        if (errorCode === ERROR_CODES.POLLFISH_PASSWORD_WEAK) {
          errors.errorMessage =
            errorData.passwordCheck.warning ||
            DEFAULT_ERRORS.POLLFISH_PASSWORD_WEAK;
        } else {
          errors.errorMessage =
            DEFAULT_ERRORS[errorCode] ||
            DEFAULT_ERRORS.POLLFISH_USERNAME_OR_PASSWORD_INVALID;
        }
      }
      if (errorStatus === 451) {
        errors.errorMessage = "";
      }

      if (ssoFailed) {
        errors.errorMessage = DEFAULT_ERRORS.SSO_FAIL;
      }
      this.setState({ errors });
    }
  }

  showErrorMessage() {
    const { location } = this.props;
    if (!isEmpty(this.state.errors.errorMessage)) {
      return (
        <div className="pf-form__message pf-form__message--error pf-form__message--padding">
          {this.state.errors.errorMessage}
        </div>
      );
    } else if ("account-invitation-expired" in location.query) {
      return (
        <div className="pf-form__message pf-form__message--error pf-form__message--padding">
          Your invitation is no longer valid.
        </div>
      );
    }
  }

  openGdprModal() {
    this.setState((prevState) => {
      return {
        ...prevState,
        gdpr: {
          ...prevState.gdpr,
          modal: true,
        },
      };
    });
  }

  closeGdprModal() {
    this.setState((prevState) => {
      return {
        ...prevState,
        gdpr: {
          ...prevState.gdpr,
          modal: false,
          isLandingRef: false,
        },
      };
    });
  }

  onClose(resetForm = true) {
    this.closeGdprModal();
    if (resetForm) {
      this.resetFormState();
    }
  }

  removeConsentCookie() {
    const { cookies } = this.props;
    cookies.remove("consent", { path: "/" });
  }

  handleChange(ref) {
    const { values } = this.state;
    values[ref] = this.refs[ref].value;
    this.setState({ values });
  }

  hideAutocompleteSuggestions = () => {
    this.refs["companyName"].blur();
  };

  removeScrollEventListener = () =>
    window.removeEventListener(
      "scroll",
      this.hideAutocompleteSuggestions,
      true
    );

  validate(ref) {
    const { errors } = this.state;
    const optional = ["otherReferral"];

    if (ref === "referral") {
      if (this.state.values[ref] === null) {
        errors[ref] = DEFAULT_ERRORS[ref];
      } else {
        errors[ref] = "";
      }
    } else if (!optional.includes(ref) && this.state.values[ref].length === 0) {
      errors[ref] = DEFAULT_ERRORS[ref];
    } else if (ref === "name") {
      if (/[.:/@]/.test(this.state.values[ref])) {
        errors[ref] = DEFAULT_ERRORS[ref];
      } else {
        errors[ref] = "";
      }
    } else {
      errors[ref] = "";
    }

    if (
      ref === "email" &&
      !errors[ref] &&
      !isEmailValid(this.refs[ref].value)
    ) {
      errors[ref] = DEFAULT_ERRORS[ref];
    }

    if (ref === "password") {
      const password = this.state.values[ref];
      if (password.length < 8) {
        errors.password = DEFAULT_ERRORS.password;
      }
    }

    this.setState({ errors });
  }

  isValidForm(userType = "publisher") {
    const { errors } = this.state;
    let invalidFields;

    if (userType !== "publisher") {
      invalidFields = !!errors.email;
    } else {
      invalidFields = Object.keys(errors).some((key) => {
        return errors[key].length > 0;
      });
    }

    return !invalidFields;
  }

  resetFormState() {
    const { errors } = this.state;
    errors.errorMessage = "";
    this.setState((prevState) => {
      return {
        ...prevState,
        errors,
        is_submitting: false,
        gdpr: {
          ...prevState.gdpr,
          data: null,
        },
      };
    });
  }

  register(gdprData = {}) {
    //prepare object for api call
    const { accountHash, referralResearcherToken } = getSearchParameters();
    let data = Object.assign({}, this.state.values, {
      ...gdprData,
    });
    data.isDeveloper =
      this.props.params.userType === "publisher" ? true : false;
    //split full name in firstName & lastName
    const name = data.name.split(" ");
    data.firstName = name.shift();
    data.lastName = name.join(" ");
    if (data.referral) {
      try {
        data.referral = data.referral.value;
      } catch (ex) {}
    }
    if (referralResearcherToken) {
      data.referralResearcherToken = referralResearcherToken;
    }

    //remove unused keys
    delete data.name;
    data.isDeveloper = this.props.params.userType === "publisher";
    data[REGISTER_PEEQ_PARAM] = this.state.peeqRegister;
    const signupDataArray = [data, accountHash];
    if (this.state.loginMethod === "pollfish") {
      signupDataArray.push("pollfish");
    }

    signupActions.signup(...signupDataArray);
  }

  handleSubmit(event) {
    event.preventDefault();
    this.resetFormState();

    if (this.props.params.userType !== "publisher") {
      this.validate("email");
      this.setState({
        is_submitting: true,
      });
      loginActions.checkForExistingEmail(this.state.values.email);
    } else {
      Object.keys(this.refs).forEach(this.validate.bind(this));
      if (this.isValidForm() && !this.state.is_submitting) {
        this.setState({
          gdpr: {
            modal: true,
          },
          is_submitting: true,
          loginMethod: "pollfish",
        });
      }
    }
  }

  render() {
    const { location } = this.props;
    const { accountHash, referralResearcherToken } = getSearchParameters();
    const isDeveloper = this.props.params.userType === "publisher";
    const peeqRegister = isInPeeqRegisterFlow(location);
    return (
      <div>
        <form onSubmit={(e) => this.handleSubmit(e)}>
          <h1 className="main-panel__title">New Account</h1>
          {this.props.params.userType === "publisher" && (
            <h3 className="main-panel__subtitle">
              Monetize your apps with a single line of code
            </h3>
          )}
          {this.props.params.userType !== "publisher" && (
            <h3 className="main-panel__subtitle">
              Create your survey in minutes, get results in hours
            </h3>
          )}
          {!peeqRegister && <SwitchUser type="signup" {...this.props} />}
          <div className="pf-form">
            {this.showErrorMessage()}
            {isDeveloper && (
              <div className="pf-form__message">
                <div
                  style={{
                    backgroundColor: "#f9fafc",
                    padding: "2px 12px",
                    borderRadius: 4,
                  }}
                >
                  <h5>Hi, we have news for you!</h5>
                  <p style={{ fontSize: 14 }}>
                    Our product is getting bigger and better! Our publisher
                    section and product have switched to our partner BitLabs.
                    Press the button below to register now.
                  </p>
                </div>
                <a
                  className="pf-form__textfield-button pf-form__textfield-button--submit text-center mb-10 mt-8"
                  href={bitLabsSignupUrl}
                  target="_blank"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Register with BitLabs
                </a>
              </div>
            )}
            {!isDeveloper && (
              <div className="pf-form__textfield">
                <label className="pf-form__textfield__label">
                  Company Email<sup>*</sup>
                </label>
                <input
                  className="pf-form__textfield__input"
                  ref="email"
                  tabIndex="3"
                  type="email"
                  onChange={this.handleChange.bind(this, "email")}
                  value={this.state.values.email}
                  placeholder="example@mail.com"
                  data-testid="company-email-input"
                />
                <div className="pf-form__message pf-form__message--error">
                  {this.state.errors.email}
                </div>
              </div>
            )}
            {!isDeveloper && (
              <Fragment>
                <div className="pf-form__textfield">
                  <button
                    style={{
                      width: "100%",
                    }}
                    className="pf-form__textfield-button pf-form__textfield-button--submit"
                    disabled={this.state.is_submitting}
                    tabIndex="4"
                    type="submit"
                    data-testid="company-email-register-button"
                  >
                    {!this.state.is_submitting && <span>Register</span>}
                    {this.state.is_submitting && <Loader />}
                  </button>
                </div>
                <div className="pf-form__textfield">
                  <div className="pf-form__textfield__or">or</div>
                </div>
              </Fragment>
            )}
          </div>
        </form>

        {!isDeveloper && (
          <Fragment>
            <div className="display-flex-row flex-center pf-form__textfield">
              <div>
                <GoogleLogin
                  {...this.props}
                  accountHash={accountHash}
                  referralResearcherToken={referralResearcherToken}
                  type="signup"
                  disabled={this.state.is_submitting}
                />
              </div>
            </div>

            <div className="pf-form__textfield">
              <SingleSignOn />
            </div>
          </Fragment>
        )}
        <Gdpr
          {...this.props}
          type="signup"
          userType={this.props.params.userType}
          modal={this.state.gdpr.modal}
          gdprData={this.state.gdpr.data}
          isLandingRef={this.state.gdpr.isLandingRef}
          onClose={(resetForm) => {
            this.onClose(resetForm);
          }}
          onSubmit={(gdprData) => {
            this.register(gdprData);
          }}
        />
      </div>
    );
  }
}

export default withCookies(Signup);
