import React from 'react';
import { ONBOARDING_STEPS_VALUES, QUALIFICATION_OPTIONS } from '../constants';
import messages from '../messages';
import { pressedEnter } from '../../common/utils';
import BackButton from './backButton';

const StepQualification  = (props) => {

  const {currentStep, value, error, handleRadioChange, handleSubmit, backAction } = props;
  if (currentStep !== ONBOARDING_STEPS_VALUES.QUALIFICATION) {
    return null;
  }

  const handleKeyPress = (e) => {
    if (pressedEnter(e)) {
      handleSubmit(e);
    }
  };

  return (
    <div>
      <div className="text-align-left">
        {backAction && (
          <BackButton onClick={backAction} />
        )}
        <h1 className="main-panel__title">{messages.generalHeading}</h1>
        <h3 className="main-panel__subtitle">{messages[ONBOARDING_STEPS_VALUES.QUALIFICATION]}:</h3>
      </div>
      <div className="pf-form pf-form--onboard">
        <div className="pf-form__textfield">
          <div>
            <label
              className="radio-button-wrapper full-width margin-btm-sm"
              data-testid="onboarding-step-like-to-create-survey"
            >
              <input
                className="radio-button__input"
                name={ONBOARDING_STEPS_VALUES.QUALIFICATION}
                type="radio"
                value={QUALIFICATION_OPTIONS.CREATE_SURVEY}
                checked={value === QUALIFICATION_OPTIONS.CREATE_SURVEY}
                onChange={handleRadioChange}
                onKeyPress={handleKeyPress}
              />
              <span className="radio-button__label">
                {messages.qualificationOptions.createSurvey}
              </span>
            </label>
            <label
              className="radio-button-wrapper full-width margin-btm-sm"
              data-testid="onboarding-step-like-to-own-list"
            >
              <input
                className="radio-button__input"
                name={ONBOARDING_STEPS_VALUES.QUALIFICATION}
                type="radio"
                value={QUALIFICATION_OPTIONS.OWN_LIST}
                checked={value === QUALIFICATION_OPTIONS.OWN_LIST}
                onChange={handleRadioChange}
                onKeyPress={handleKeyPress}
              />
              <span className="radio-button__label">
                {messages.qualificationOptions.ownList}
              </span>
            </label>
            <label
              className="radio-button-wrapper full-width margin-btm-sm"
              data-testid="onboarding-step-like-to-get-paid"
            >
              <input
                name={ONBOARDING_STEPS_VALUES.QUALIFICATION}
                value={QUALIFICATION_OPTIONS.GET_PAID}
                checked={value === QUALIFICATION_OPTIONS.GET_PAID}
                type="radio"
                onChange={handleRadioChange}
                onKeyPress={handleKeyPress}
              />
              <span className="radio-button__label">
                {messages.qualificationOptions.getPaid}
              </span>
            </label>
          </div>
          <div className="pf-form__message pf-form__message--error">{ error }</div>
        </div>
        <div className="pf-form__textfield">
          {value === QUALIFICATION_OPTIONS.GET_PAID && (
            <a
              className={`pf-form__textfield-button full-width pf-form__textfield-button--submit text-center`}
              tabIndex="4"
              type="button"
              href="https://www.swagbucks.com/g/paid-surveys?cmp=2766&cxid=pollfish_onboarding"
              target="_blank"
              style={{ display: 'block' }}
            >
              {messages.getPaidAction}
            </a>
          )}
          {value !== QUALIFICATION_OPTIONS.GET_PAID && (
            <button
              data-testid='qualification-next-button'
              className={`pf-form__textfield-button full-width pf-form__textfield-button--submit`}
              tabIndex="4"
              type="button"
              onClick={handleSubmit}
            >
              {messages.next}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default StepQualification;
