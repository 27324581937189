import { get } from "lodash";
import {
  REGISTER_PEEQ_PARAM,
  EMAIL_VERIFY_ERROR_CODES,
} from "../common/constants";

export function isEmailValid(email) {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function getSearchParameters() {
  let prmstr = decodeURIComponent(window.location.search.substr(1));
  return prmstr != null && prmstr !== "" ? transformToAssocArray(prmstr) : {};
}

function transformToAssocArray(prmstr) {
  let params = {};
  let prmarr = prmstr.split("&");
  for (let i = 0; i < prmarr.length; i++) {
    let tmparr = prmarr[i].split("=");
    params[tmparr[0]] = tmparr[1];
  }
  return params;
}

export const pressedEnter = (e) => {
  return e.key === "Enter";
};

export const isInPeeqRegisterFlow = (location, allowParamCheck = false) => {
  if (allowParamCheck) {
    const peeqRegister = get(location?.query, REGISTER_PEEQ_PARAM);
    if (peeqRegister === "true") {
      return true;
    }
  }
  return location?.pathname?.indexOf("/signup/account") >= 0;
};

export const isEmailStatusValid = (emailStatus) =>
  !EMAIL_VERIFY_ERROR_CODES.includes(emailStatus);
