import React from 'react';
import { ONBOARDING_STEPS_VALUES } from '../constants';
import messages from '../messages';
import { pressedEnter } from '../../common/utils';
import BackButton from './backButton';

const StepFullName  = (props) => {

  const {currentStep, value, error, handleChange, handleSubmit, backAction } = props;
  if (currentStep !== ONBOARDING_STEPS_VALUES.FULL_NAME) {
    return null;
  }

  return (
    <div>
      <div className="text-align-left">
        {backAction && (
          <BackButton onClick={backAction} />
        )}
        <h1 className="main-panel__title">{messages.generalHeading}</h1>
        <h3 className="main-panel__subtitle">{messages[ONBOARDING_STEPS_VALUES.FULL_NAME]}<sup>*</sup></h3>
      </div>
      <div className="pf-form pf-form--onboard">
        <div className="pf-form__textfield">
          <input
            className="pf-form__textfield__input"
            name={ONBOARDING_STEPS_VALUES.FULL_NAME}
            tabIndex="1"
            autoFocus={true}
            value={value}
            onChange={handleChange}
            onKeyPress={(e) => {
              if (pressedEnter(e)) {
                handleSubmit(e);
              }
            }}
            type="text"
            placeholder="John Doe"
            data-testid='full-name-input'
          />
          <div className="pf-form__message pf-form__message--error">{ error }</div>
        </div>
        <div className="pf-form__textfield">
          <button
            data-testid='full-name-next-button'
            className={`pf-form__textfield-button full-width pf-form__textfield-button--submit`}
            tabIndex="4"
            type="button"
            onClick={handleSubmit}
          >
            {messages.next}
          </button>
        </div>
      </div>
    </div>
  );
};

export default StepFullName;
