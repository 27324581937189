import React from 'react';

const BackButton = ({ onClick }) => (
  <button type="button" className="onboard__back-btn margin-btm-xs" onClick={onClick}>
    <i className="onboard-sidebar__back-btn-icon icon-arrow-left"></i>
    back
  </button>
);

export default BackButton;
