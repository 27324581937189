import React from 'react';
import ReactDOM from 'react-dom';
import Router from 'react-router/lib/Router';
import browserHistory from 'react-router/lib/browserHistory';
import { CookiesProvider } from 'react-cookie';

import routes from './routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './sass/base.scss';

// Initiate Sentry SDK
/* eslint no-undef:0 */

// configure sentry only in production mode
if (process.env.NODE_ENV === 'production') {
  Raven.config('https://c1a96ef13549479aae2f11ce7daa8936@sentry.io/149112').install();
}

ReactDOM.render(
  <CookiesProvider>
    <Router routes={routes} history={browserHistory} />
  </CookiesProvider>,
  document.getElementById('root')
);
