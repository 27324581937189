import React from 'react';
import Link from 'react-router/lib/Link';

class switchAction extends React.Component {

  render() {
    return (
      this.renderContent()
    );
  }

  renderContent() {
    return (this.props.action === 'signup') ? this.renderGoToLoginAction() : this.renderGoToSignupAction();
  }

  renderGoToLoginAction() {
    return (
      <div className="switch-action">
        <div className="switch-action__label">Already a user?</div>
        <Link className="pf-btn pf-btn--inline switch-action__btn pf-btn--bordered" to={`/login/${this.props.params.userType}`}>Log in</Link>
      </div>
    );
  }

  renderGoToSignupAction() {
    return (
      <div className="switch-action">
        <div className="switch-action__label">Don't have an account?</div>
        <Link
          className="pf-btn pf-btn--inline switch-action__btn pf-btn--bordered"
          to={`/signup/${this.props.params.userType || 'researcher'}`}
        >
          Get Started
        </Link>
      </div>
    );
  }

};

export default switchAction;
