import { createActions } from 'reflux';

const loginActions = createActions([
  'fetch',
  'login',
  'google',
  'googleOnboard',
  'analytics',
  'gdprConsent',
  'ssoClosed',
  'ssoSuccess',
  'ssoFail',
  'checkForExistingEmail',
]);

export default loginActions;
