import React from 'react';
import Link from 'react-router/lib/Link';

class switchUser extends React.Component {

  render() {
    return (
      <div className="switch-user margin-btm-lg">
        <Link
          className="switch-user__btn"
          activeClassName="switch-user__btn--active"
          to={`/${this.props.type}/researcher`}
          data-testid="i-am-researcher-link"
        >
          I'm a researcher
        </Link>
        <Link
          className="switch-user__btn"
          activeClassName="switch-user__btn--active"
          to={`/${this.props.type}/publisher`}
          data-testid="i-am-publisher-link"
        >
          I'm a publisher
        </Link>
      </div>
    );
  }
};

export default switchUser;
