import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Checkbox, Col, Modal, Row } from 'react-bootstrap';
import 'core-js/es7/array';
import 'core-js/fn/string/includes';

import GdprContent from './GdprContent';
import Loader from './Loader';
import loginActions from '../login/login_actions';

class Gdpr extends React.Component {
  static propTypes = {
    type: PropTypes.oneOf(['login', 'signup']),
    userType: PropTypes.oneOf(['researcher', 'publisher', 'account']),
    modal: PropTypes.bool,
    gdprData: PropTypes.object,
    isLandingRef: PropTypes.bool,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
  };

  static defaultProps = {
    modal: false,
    userType: 'researcher',
    gdprData: {
      consentTypes: [
        'GDPR',
        'Newsletter',
      ],
    },
    isLandingRef: false,
  };

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.modal === false) {
      this.resetState();
    }
  }

  getInitialState = () => ({
    newsletter: {
      checked: false,
    },
    termsCanceled: false,
    isSubmitting: false,
  });

  toggleSubmitting() {
    this.setState({
      isSubmitting: !this.state.isSubmitting,
    });
  }

  resetState() {
    this.setState(this.getInitialState());
  }

  toggleNewsletter = () => {
    this.setState({ newsletter: { checked: !this.state.newsletter.checked } })
  }

  cancelTerms = () => {
    this.setState({ termsCanceled: true })
  }

  goToTerms = () => {
    this.setState({ termsCanceled: false })
  }

  getGdprConsent() {
    let { gdprData } = this.props;
    if (!gdprData) {
      return false;
    }

    if (
      !this.state.newsletter.checked &&
      gdprData.consentTypes &&
      gdprData.consentTypes.includes('Newsletter')
    ) {
      return {
        ...gdprData,
        consentTypes: [
          ...gdprData.consentTypes.filter(item => !item.includes('Newsletter'))
        ],
      };
    }
    return gdprData;
  }

  acceptTerms = () => {
    loginActions.gdprConsent(this.getGdprConsent(), this.props.type);
  }

  isLoginPath = () => this.props.type === 'login'

  renderGdprModal = () => {
    const submitButton = (
      <button
        style={{
          width: '100%',
        }}
        className="pf-form__textfield-button pf-form__textfield-button--submit"
        tabIndex="3"
        type="button"
        disabled={this.state.isSubmitting}
        onClick={() => {
          this.toggleSubmitting();
          /*
            The button will trigger
           */
          // this is when in login page
          // this is when you came from a landing page and you only need to consent(not sign up)
          if (
            this.isLoginPath() ||
            (this.getGdprConsent() && this.props.isLandingRef)
          ) {
            this.acceptTerms()
          } else {
            this.props.onSubmit(this.getGdprConsent())
          }
        }
        }
      >
        {!this.state.isSubmitting && <span>I accept</span>}
        {this.state.isSubmitting && <Loader />}
      </button>
    );
    return (
      <Modal
        show={this.props.modal && !this.state.termsCanceled}
      >
        <Modal.Header>
          <Modal.Title>Pollfish Terms Of Service &amp; Privacy Policy</Modal.Title>
        </Modal.Header>
        <Modal.Body className="gdpr__modal-body">
          <div className="gdpr__disclaimer">
            Please accept our updated terms to continue using Pollfish
          </div>
          <div className="gdpr__main-content">
            <GdprContent
              type={this.props.userType}
            />
          </div>
          <div className="gdpr__main-content">
            <Alert
              bsStyle="success"
              bsClass="gdpr__checkbox-container"
            >
              <Checkbox
                checked={this.state.newsletter.checked}
                onChange={() => this.toggleNewsletter()}
              >
                I want to receive important updates and offers from pollfish
              </Checkbox>
            </Alert>
          </div>
          <div
            className="gdpr__main-content"
            style={{
              fontSize: 12,
            }}
          >
            By tapping I accept, you accept the updated Terms. If you don’t want to accept the terms, <a href="https://www.pollfish.com/lp/gdpr/" target="_blank">see your options</a>.
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Row>
            <Col smHidden mdHidden lgHidden xs={12} smOffset={5} sm={4}>
              {submitButton}
            </Col>
            <Col xs={12} sm={3}>
              <a
                style={{
                  display: 'block',
                  width: '100%',
                  color: '#5c6776',
                  textAlign: 'center',
                  paddingTop: 16,
                  paddingBottom: 16,
                }}
                href="#"
                disabled={this.state.isSubmitting}
                onClick={(e) => {
                  e.preventDefault();
                  if (this.isLoginPath()) {
                    this.cancelTerms()
                  } else {
                    this.props.onClose()
                  }
                }}
              >
                Decide later
              </a>
            </Col>
            <Col xsHidden xs={12} smOffset={5} sm={4}>
              {submitButton}
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }

  renderGdprConfirmModal = () => {
    const submitButton = (
      <button
        style={{
          width: '100%',
        }}
        className="pf-form__textfield-button pf-form__textfield-button--submit"
        tabIndex="3"
        type="button"
        onClick={() => this.goToTerms()}
      >
        back to terms
      </button>
    );

    return (
      <Modal
        show={this.props.modal && this.state.termsCanceled && this.isLoginPath()}
      >
        <Modal.Header>
          <Modal.Title>
            Please accept our updated terms to continue using Pollfish
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="gdpr__disclaimer">
            If you don’t want to accept the terms, <a href="https://www.pollfish.com/lp/gdpr/" target="_blank">see your options</a>.
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Row>
            <Col smHidden mdHidden lgHidden xs={12} smOffset={5} sm={4}>
              {submitButton}
            </Col>
            <Col xs={12} sm={3}>
              <a
                href="#"
                style={{
                  display: 'block',
                  width: '100%',
                  color: '#5c6776',
                  textAlign: 'center',
                  paddingTop: 16,
                  paddingBottom: 16,
                }}
                onClick={(e) => {
                  e.preventDefault();
                  this.props.onClose();
                }}
              >
                Cancel
              </a>
            </Col>
            <Col xsHidden xs={12} smOffset={5} sm={4}>
              {submitButton}
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    )
  }

  render() {
    return (
      <div className="static-modal gdpr">
        {this.renderGdprModal()}
        {this.renderGdprConfirmModal()}
      </div>
    );
  }
};

export default Gdpr;
