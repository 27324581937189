import React from 'react';
import { ONBOARDING_STEPS_VALUES } from '../constants';
import messages from '../messages';
import GdprContent from '../../common/GdprContent';
import { pressedEnter } from '../../common/utils';
import BackButton from './backButton';

const StepLegal  = (props) => {

  const {currentStep, value, handleSubmit, disabled, error, handleChange, handleDeclineTerms, errorMessage, backAction } = props;
  if (currentStep !== ONBOARDING_STEPS_VALUES.LEGAL) {
    return null;
  }
  return (
    <div>
      <div className="text-align-left">
        {backAction && (
          <BackButton onClick={backAction} />
        )}
        <h1 className="main-panel__title">{messages[ONBOARDING_STEPS_VALUES.LEGAL]}</h1>
        <h3 className="main-panel__subtitle">{messages.legalHeading}</h3>
        <h5 className="color-green margin-top-lg margin-btm-md">{messages.legalPrompt}</h5>
      </div>
      <div className="pf-form pf-form--onboard pf-form--long">
        <div className="pf-form__textfield">
          <GdprContent type="researcher" />
          <div className="pf-form__message pf-form__message--error">{ error }</div>
        </div>
      </div>
      <label className="checkbox-wrapper margin-btm-lg margin-top-lg">
        <input
          className="checkbox__input"
          type="checkbox"
          name="newsletter"
          onKeyPress={(e) => {
            if (pressedEnter(e)) {
              handleSubmit(e);
            }
          }}
          checked={value}
          onChange={handleChange}
        />
        {value && <i className="checkbox__active-state icon-checked"></i>}
        {!value && <i className="checkbox__active-state checkbox__active-state--unchecked icon-unchecked"></i>}
        <span className="checkbox__label">
            {messages.newsletterConsent}
          </span>
      </label>
      <div className="pf-form pf-form--onboard">
        <div className="pf-form__textfield">
          {errorMessage}
          <button
            data-testid='terms-accept-button'
            className={`pf-form__textfield-button full-width pf-form__textfield-button--submit`}
            tabIndex="4"
            disabled={disabled}
            onClick={handleSubmit}
            type="button"
          >
            {messages.accept}
          </button>
          {error && (
            <div className="pf-form__message pf-form__message--error margin-top-md margin-btm-md">{ error }</div>
          )}
          <button
            data-testid='terms-not-accept-button'
            className={`pf-form__textfield-button full-width pf-form__textfield-button--cancel`}
            tabIndex="4"
            type="button"
            onClick={handleDeclineTerms}
          >
            {messages.notAccept}
          </button>
        </div>
      </div>
    </div>
  );
};

export default StepLegal;
