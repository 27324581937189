import React from 'react';

class Copyright extends React.Component {

  render() {
    return (
      <div className="pf-copyright">
      &copy; { new Date().getFullYear() } Pollfish. All Rights Reserved
      </div>
    );
  }

};

export default Copyright;

