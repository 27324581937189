import React from 'react';
import { BroadcastChannel } from 'broadcast-channel';
import { get } from 'lodash';
import { BROADCAST_CHANNEL_NAME, MessageTypes, OUTCOME_FAIL, OUTCOME_SUCCESS } from '../constants';

const getFullName = (queryParams) => {
  const firstName = queryParams.firstName;
  const lastName = queryParams.lastName;
  let fullName = '';
  if (firstName) {
    fullName += firstName
  }
  if (lastName) {
    fullName += ` ${lastName}`;
  }
  return fullName.trim();
}
class SsoFinalRedirect extends React.Component {
  componentDidMount() {
    const outcome = get(this.props, 'params.outcome');
    this.broadcastChannel = new BroadcastChannel(BROADCAST_CHANNEL_NAME);
    switch (outcome) {
      case OUTCOME_SUCCESS: {
        const queryParams = get(this.props, 'location.query', {});
        const token = queryParams.token;
        this.broadcastChannel.postMessage({
          type: MessageTypes.ssoSuccess,
          token,
          fullName: getFullName(queryParams),
        });
        break;
      }
      case OUTCOME_FAIL:
      default:
        this.broadcastChannel.postMessage({
          type: MessageTypes.ssoFail,
          // reason: 'some reason',
        });
        break;
    }
    window.close();
  }

  componentDidUnmount() {
    // this is probably not needed since window.close runs immediately, but kept for robustness
    this.broadcastChannel.close();
  }
  render() {
    return <div>You can safely close this window</div>;
  }
}

export default SsoFinalRedirect;