import React from 'react';
import Logo from '../../Logo';

export const RedirectForm = () => {
  const isLocalhost = window.location.hostname === 'localhost';
  const hasPort = window.location.port;
  const baseURL = isLocalhost && hasPort ? `http://localhost:5005` : '';
  return (
    <section className="sso-page">
      <div style={{flex: 1}}>
        <div style={{ marginTop: 8, marginBottom: 8 }}>
          <Logo />
        </div>
        <h4 className="text-align-left" style={{ marginBottom: 18, marginTop: 0 }}>Sign in with Single Sign-on</h4>
        <div className="sso-form">
          <form action={`${baseURL}/api/v3/user/authenticate/sso`} method="POST">
            <label for="email">Email</label>
            <input id="email" type="email" placeholder="Enter your email" name="email" />
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </section>
  )
};

export default RedirectForm;
