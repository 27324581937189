import React from 'react';
import PropTypes, { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import Link from 'react-router/lib/Link';

import Loader from '../common/Loader';
import Gdpr from '../common/gdpr';
import { DEFAULT_ERRORS } from '../common/constants';
import GoogleLogin from '../common/google_login/google_login';
import loginActions from './login_actions';
import loginStore from './login_store';
import { isEmailValid } from '../common/utils';
import SingleSignOn from '../common/SingleSignOn';

class Login extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
    params: PropTypes.object,
    location: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  getInitialState() {
    return {
      sessionExpiration: {
        error: ''
      },
      errors: {
        email: '',
        password: '',
        wrong: ''
      },
      values: {
        email: '',
        password: '',
      },
      gdpr: {
        modal: false,
        data: null,
        consent: false,
      },
    }
  }

  componentWillMount() {
    if(this.props.location.query && this.props.location.query.redirect_url){
      this.setState({
        sessionExpiration: {
          error: 'Your session has expired. Please login again to continue working.'
        }
      })
    }
    loginActions.fetch();
    this.unsubscribe = loginStore.listen(this.onDataChange.bind(this));
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  onDataChange(data) {
    this.removeConsentCookie();

    if (data.token) {
      let url = `/onboarding?token=${data.token}`;
      if (data.fullName) {
        url += `&fullName=${data.fullName}`;
      }
      this.props.router.push(url);
      return;
    }

    if (data.loggedIn && !data.isGuest) {
      const { redirect_url } = this.props.location.query;
      if (redirect_url) {
        window.location = `${window.location.protocol}//${window.location.host}${redirect_url}`;
        return false;
      }

      const location = (!data.isPublisher) ? '/dashboard/surveys' : '/dashboard/dev/';
      window.location = location;
    }

    if (data.fail) {
      this.onClose(true);
      if (data.errorStatus === 451) {
        this.openGdprModal(data.errorData)
      } else {
        this.closeGdprModal()
      }

      if (data.errorStatus !== 451) {
        this.setState({ is_submitting: false });
      }

      let { errors } = this.state;
      let { errorData } = data;
      errors.wrong = DEFAULT_ERRORS.POLLFISH_USERNAME_OR_PASSWORD_INVALID;
      if (data.errorStatus === 400) {
        errors.wrong = errorData && errorData.errors ?
          DEFAULT_ERRORS[errorData.errors[0]] || DEFAULT_ERRORS.POLLFISH_USERNAME_OR_PASSWORD_INVALID
          : DEFAULT_ERRORS.POLLFISH_USERNAME_OR_PASSWORD_INVALID;
      } else if (data.errorStatus === 503) {
        errors.wrong = DEFAULT_ERRORS.service_unavailable;
      } else if (data.errorStatus === 451) {
        errors.wrong = '';
      }
      if (data.ssoFailed) {
        errors.wrong = DEFAULT_ERRORS.SSO_FAIL;
      }
      this.setState({ errors });
    }
  }

  showErrorMessage() {
    const { location } = this.props;
    if (this.state.errors.wrong.length > 0) {
      return this.renderErrorMessage()
    } else if ('account-invitation-expired' in location.query) {
      return (
        <div className="pf-form__message pf-form__message--error pf-form__message--padding">
          Your invitation is no longer valid.
        </div>
      )
    }
  }

  showSessionExpirationMessage() {
    return (
      <div className="pf-form__message pf-form__message--error pf-form__message--padding">
        {this.state.sessionExpiration.error}
      </div>
    );
  }

  renderErrorMessage() {
    return (
      <div className="pf-form__message pf-form__message--error pf-form__message--padding">
        {this.state.errors.wrong}
      </div>
    );
  }

  getTagline() {
    return 'TO ADD SOMETHING HERE'
  }

  handleChange(ref) {
    const { values } = this.state;
    values[ref] = this.refs[ref].value;
    this.setState({ values });
  }

  openGdprModal(data = null) {
    this.setState((prevState) => {
      return {
        ...prevState,
        gdpr: {
          ...prevState.gdpr,
          modal: true,
          data,
        },
      }
    });
  }

  closeGdprModal() {
    this.setState((prevState) => {
      return {
        ...prevState,
        gdpr: {
          ...prevState.gdpr,
          modal: false,
          data: null,
        },
      }
    });
  }

  onClose(resetForm = true){
    this.closeGdprModal();
    if (resetForm) {
      this.resetFormState();
    }
  }

  validate(ref) {
    const { errors } = this.state;
    if (this.refs[ref].value.length === 0) {
      errors[ref] = DEFAULT_ERRORS[ref];
    } else {
      errors[ref] = '';
    }

    if (ref === 'email' && !errors[ref] && !isEmailValid(this.refs[ref].value)) {
      errors[ref] = DEFAULT_ERRORS[ref];
    }

    this.setState({ errors });
  }

  invalidForm() {
    const { errors } = this.state;
    return Object.keys(errors).some((key) => {
      return errors[key].length > 0;
    });
  }

  removeConsentCookie() {
    const { cookies } = this.props;
    cookies.remove('consent', { path: '/' });
  }

  resetFormState() {
    const { errors } = this.state;
    errors.wrong = '';
    this.setState({
      errors,
      is_submitting: false,
      sessionExpiration: {
        error: ''
      }
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.resetFormState();
    //validate form fields
    Object.keys(this.refs).forEach(this.validate.bind(this));
    if (!this.invalidForm() && !this.state.is_submitting) {
      this.setState({ is_submitting: true });
      let data = Object.assign({}, this.state.values);
      loginActions.login(data);
    }
  }

  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <h1 className="main-panel__title">Log in to Pollfish</h1>
          <h3 className="main-panel__subtitle">Create your survey in minutes, get results in hours</h3>
          <div className="pf-form">
            {this.showSessionExpirationMessage()}
            {this.showErrorMessage()}
            <div className="pf-form__textfield">
              <label className="pf-form__textfield__label">Company Email</label>
              <input
                className="pf-form__textfield__input"
                tabIndex="1"
                ref="email"
                type="email"
                onChange={this.handleChange.bind(this, 'email')}
                value={this.state.values.email}
                placeholder="example@mail.com"
              />
              <div className="pf-form__message pf-form__message--error">{this.state.errors.email}</div>
            </div>
            <div className="pf-form__textfield">
              <label className="pf-form__textfield__label pf-form__textfield__label--pull-left">Password</label>
              <a href="/forgotpassword"
                 className="pf-form__textfield__label pf-form__textfield__label--pull-right pf-link">Forgot
                password?</a>
              <input
                className="pf-form__textfield__input pf-clearer"
                tabIndex="2"
                minLength="3"
                ref="password"
                onChange={this.handleChange.bind(this, 'password')}
                type="password"
                placeholder="4+ characters"
              />
              <div className="pf-form__message pf-form__message--error">{this.state.errors.password}</div>
            </div>
            <div className="pf-form__textfield">
              <button
                style={{
                  width: '100%',
                }}
                className="pf-form__textfield-button pf-form__textfield-button--submit"
                disabled={this.state.is_submitting}
                tabIndex="3"
                type="submit"
              >
                {!this.state.is_submitting && <span>Log in</span>}
                {this.state.is_submitting && <Loader />}
              </button>
            </div>
            <div className="pf-form__textfield">
              <div className="pf-form__textfield__or">or</div>
            </div>
          </div>
        </form>

        <div className="display-flex-row flex-center pf-form__textfield">
          <div>
            <GoogleLogin
              {...this.props}
              type="login"
              disabled={this.state.is_submitting}
            />
          </div>

          {/* <Link
            className="pf-btn pf-btn--guest"
            to="/login-guest"
          >
            Continue as guest
          </Link> */}
        </div>

        <div className="pf-form__textfield">
          <SingleSignOn />
        </div>
        <Gdpr
          type="login"
          userType={this.props.params.userType}
          modal={this.state.gdpr.modal}
          gdprData={this.state.gdpr.data}
          onClose={(resetForm) => {
            this.onClose(resetForm)
          }}
        />
      </div>
    );
  }
};

export default withCookies(Login);
