import shuffle from 'lodash/shuffle';
import React from 'react';
import Select from 'react-select';
import { ONBOARDING_STEPS_VALUES } from '../constants';
import messages from '../messages';
import { pressedEnter } from '../../common/utils';
import BackButton from './backButton';

const getReferralOptions = () => {
  const shuffledOptions = shuffle([
    {
      value: 0,
      text: 'Advertisement',
    },
    {
      value: 1,
      text: 'Conference',
    },
    {
      value: 3,
      text: 'Friend / Colleague',
    },
    {
      value: 4,
      text: 'Google',
    },
    {
      value: 7,
      text: 'Video',
    },
    {
      value: 9,
      text: 'LinkedIn',
    },
  ]);

  shuffledOptions.push({
    value: 5,//Other's value is 5. Do not change
    text: 'Other',
  });

  return shuffledOptions;
};

const referralOptions = getReferralOptions();

const StepReferral  = (props) => {

  const {currentStep, value, otherValue, error, handleChange, handleSelectChange, handleSubmit, backAction } = props;
  if (currentStep !== ONBOARDING_STEPS_VALUES.REFERRAL) {
    return null;
  }

  return (
    <div>
      {backAction && (
        <BackButton onClick={backAction} />
      )}
      <h1 className="main-panel__title text-align-left">{messages.generalHeading}</h1>
      <h3 className="main-panel__subtitle text-align-left">{messages[ONBOARDING_STEPS_VALUES.REFERRAL]}<sup>*</sup></h3>
      <div className="pf-form pf-form--onboard">
        <div className="pf-form__textfield">
          <Select
            className="pf-select"
            name='referral'
            id='referral-select'
            clearable={false}
            isSearchable={false}
            onChange={handleSelectChange}
            value={value}
            autoBlur={true}
            tabIndex={"4"}
            options={referralOptions.map((option) =>  ({ value:option.value, label:option.text }))}>
          </Select>
          <div className="pf-form__message pf-form__message--error">{ error }</div>
        </div>
        {value && value.value === 5 && (
          <div className="pf-form__textfield">
            <label className="pf-form__textfield__label">Please specify (optional)</label>
            <input
              className="pf-form__textfield__input"
              name={ONBOARDING_STEPS_VALUES.REFERRAL_OTHER}
              tabIndex="4"
              value={otherValue}
              autoFocus={true}
              onChange={handleChange}
              onKeyPress={(e) => {
                if (pressedEnter(e)) {
                  handleSubmit(e);
                }
              }}
              type="text"
            />
          </div>
        )}
        <div className="pf-form__textfield">
          <button
            data-testid='referral-next-button'
            className={`pf-form__textfield-button full-width pf-form__textfield-button--submit`}
            tabIndex="4"
            type="button"
            onClick={handleSubmit}
          >
            {messages.next}
          </button>
        </div>
      </div>
    </div>
  );
};

export default StepReferral;
