import { createStore } from "reflux";
import request from "superagent";
import { get } from "lodash";
import loginActions from "./login_actions";
import { REGISTER_PEEQ_PARAM } from "../common/constants";

const getFullName = (data) => {
  const firstName =
    typeof data.maybeFirstName === "string" ? data.maybeFirstName : "";
  const lastName =
    typeof data.maybeLastName === "string" ? data.maybeLastName : "";
  return `${firstName} ${lastName}`.trim();
};

const loginStore = createStore({
  listenables: [loginActions],

  onFetch(isGuestLoginView) {
    request.get("/api/v2/user").end((err, res) => {
      if (err) {
        if (isGuestLoginView) {
          this.onGuestLogin();
        }

        return false;
      }
      if (res.statusCode === 200) {
        const { isGuest } = res.body;

        if (!isGuest) {
          this.trackAnalytics(res.body);
        }

        this.trigger({
          loggedIn: true,
          isPublisher: !!res.body.is_developer,
          isGuest,
        });
      }
    });
  },

  onLogin(data) {
    request
      .post("/api/v3/user/login")
      .send(data)
      .set("Accept", "application/json")
      .end((err, res) => {
        if (err) {
          return this.trigger({
            fail: true,
            errorStatus: err.status,
            errorData: err.response.body,
          });
        }

        if (res) {
          if (res.statusCode === 200) {
            this.trackAnalytics(res.body, null, "pollfish");
            this.trigger({ loggedIn: true, isPublisher: !!res.body.publisher });
          } else {
            this.trigger({ fail: true });
          }
        }
      });
  },

  onGuestLogin(data) {
    request
      .post("/api/v3/user/guest/login")
      .send(data)
      .set("Accept", "application/json")
      .end((err, res) => {
        if (err) {
          return this.trigger({
            fail: true,
            errorStatus: err.status,
            errorData: err.response.body,
          });
        }

        if (res) {
          if (res.statusCode === 200) {
            this.trigger({ loggedIn: true, surveyId: res.body.surveyId });
          } else {
            this.trigger({ fail: true });
          }
        }
      });
  },

  onGoogle({
    data,
    role,
    body = {},
    accountHash,
    referralResearcherToken,
    ...restProps
  }) {
    const peeqRegister = get(restProps, REGISTER_PEEQ_PARAM, false);
    request
      .post("/api/v3/user/authenticate/google")
      .query({
        token: data.tokenId,
        isDeveloper: role === "publisher" ? true : false,
        accountHash: accountHash,
        referralResearcherToken: referralResearcherToken,
        [REGISTER_PEEQ_PARAM]: peeqRegister,
      })
      .send(body)
      .end((err, res) => {
        if (err) {
          return this.trigger({
            fail: true,
            errorStatus: err.status,
            errorData: err.response.body,
          });
        }

        if (res) {
          if (res.statusCode === 200) {
            if (role === "publisher" || !res.body.maybeFirstName) {
              this.trackAnalytics(res.body, null, "google");
              this.trigger({
                loggedIn: true,
                isPublisher: !!res.body.publisher,
              });
            } else {
              return this.trigger({
                token: res.body.token,
                fullName: getFullName(res.body),
              });
            }
          } else {
            this.trigger({ fail: true });
          }
        }
      });
  },

  onGdprConsent(data, type) {
    request
      .post("/api/v3/user/consent")
      .set("Accept", "application/json")
      .send(data)
      .end((err, res) => {
        if (err) {
          return this.trigger({
            fail: true,
            errorStatus: err.status,
            errorData: err.response ? err.response.body : null,
          });
        }

        if (res) {
          if (res.statusCode === 200) {
            /**
             * This is the case when someone is coming from a landing page
             * and the only thing that needs to be done is to consent the GDPR terms.
             */
            if (type === "signup") {
              this.trackAnalytics(res.body);
            }
            this.trigger({ loggedIn: true });
          } else {
            this.trigger({ fail: true });
          }
        }
      });
  },

  onSsoClosed() {
    // not sure we should handle the panel closed someway
    // this.trigger({ fail: true });
  },
  onSsoSuccess({ token, fullName } = {}) {
    if (token) {
      this.trigger({ token, fullName });
    } else {
      this.trigger({ loggedIn: true });
    }
  },
  onSsoFail() {
    this.trigger({ fail: true, ssoFailed: true });
  },

  onAnalytics(user, type, loginMethod) {
    this.trackAnalytics(user, type, loginMethod);
  },

  onCheckForExistingEmail(email) {
    request.get(`/api/v3/user/exists?email=${email}`).end((err, res) => {
      if (err) {
        return this.trigger({
          fail: true,
          errorStatus: err.status,
          errorData: err.response.body,
        });
      }
      this.trigger({
        emailExists: res.body.exists,
        verifyResponse: res.body.verifyResponse,
      });
    });
  },

  trackAnalytics(user, type, loginMethod) {
    try {
      const analyticsData = {
        email: user.email,
      };
      if (loginMethod) {
        analyticsData.loginMethod = loginMethod;
      }
      if (window.analytics) {
        window.analytics.identify(user.id, analyticsData);
        if (user.isNew) {
          // the alias API is expecting the user.id to be a string
          window.analytics.alias(user.id.toString());
          window.analytics.track("register", {
            category: user.publisher ? "developer" : "researcher",
          });
        }
      }
    } catch (ex) {
      window.Raven.captureException(ex);
    }
  },
});

export default loginStore;
