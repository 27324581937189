import React from 'react';
import loginActions from '../../login/login_actions';
import { BroadcastChannel } from 'broadcast-channel';
import { BROADCAST_CHANNEL_NAME, MessageTypes } from './constants';

class SingleSignOn extends React.Component{
  constructor(props) {
    super(props);
    this.openedWindow = undefined;
  }

  checkOpenedWindow = () => {
    if (!this.openedWindow) {
      return;
    }
    if (this.openedWindow.closed) {
      loginActions.ssoClosed();
      this.openedWindow = false;
      this.broadcastChannel.close();
      clearInterval(this.timer);
    }
  }
  handleClick = () => {
     // Not sure about this.
    if (this.openedWindow) {
      this.openedWindow.focus();
      return;
    }
    this.openedWindow = window.open('/sso', 'Pollfish SSO Login', `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
    width=600,height=600,left=100,top=100`);
    this.timer = setInterval(this.checkOpenedWindow, 500);
    this.broadcastChannel = new BroadcastChannel(BROADCAST_CHANNEL_NAME);
    this.broadcastChannel.onmessage = (message) => {
      switch (message.type) {
        case MessageTypes.ssoSuccess:
          loginActions.ssoSuccess({ token: message.token, fullName: message.fullName });
          break;
        case MessageTypes.ssoFail:
        default:
          loginActions.ssoFail();
          break;
      }
    };
  }
  render() {
    return (
      <button className="pf-btn pf-btn--sso" onClick={this.handleClick}>
        Sign in with Single Sign-on (SSO)
      </button>
    );
  }
}

export default SingleSignOn;