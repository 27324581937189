import React from 'react';
import Route from 'react-router/lib/Route';
import Redirect from 'react-router/lib/Redirect';
import Layout from '../components/common/Layout';
import Login from '../components/login';
// import GuestLogin from '../components/login/guest';
import Onboarding from '../components/onboarding';
import Signup from '../components/signup';
import SingleSignOnRedirectForm from '../components/common/SingleSignOn/RedirectForm';
import SsoFinalRedirect from '../components/common/SingleSignOn/FinalRedirect';

const Routes = (
	<div>
    <Route path="/" component={Layout}>
      <Redirect from="login/:userType" to="login/" />
      <Redirect from="signup" to="signup/researcher" />
      <Route path="signup/:userType" component={Signup} />
      <Route path="login" component={Login} />
      {/* <Route path="login-guest" component={GuestLogin} /> */}
      <Route path="login-guest" component={RedirectToHomepage} />
    </Route>
    <Route path="/onboarding" component={Onboarding} />
    <Route exact path="/sso/:outcome" component={SsoFinalRedirect} />
    <Route path="/sso" component={SingleSignOnRedirectForm} />
  </div>
);

export default Routes;


function RedirectToHomepage(){
  window.location.replace("/")
}