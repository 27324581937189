import React from 'react';

function Loader({size = 'small'}) {
  let className = 'loader';
  if (size === 'big') {
    className = 'loader big'
  }

  return (
    <div className="loader-container">
      <div className={className}></div>
    </div>
  );
};

export default Loader;
