import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import ImgAppCreators from '../../../images/AppCreators.png';
import ImgSurveyMakers from '../../../images/SurveyMakers.png';

const settings = {
  dots: true,
  arrows: false,
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: false,
  speed: 900,
  fade: true,
  autoplay: false,
  autoplaySpeed: 3000,
  centerMode: true,
  centerPadding: '20px',
  variableWidth: false,
  lazyLoad: true
};

class SlideBar extends React.Component {

  render() {
    return (
      <div className="featured-panel">
        <div className="decorative-triangle decorative-triangle--1"></div>
        <div className="decorative-triangle decorative-triangle--2"></div>
        <div className="decorative-triangle decorative-triangle--3"></div>
          { this.renderSlider() }
      </div>
    );
  }

  renderSlider() {
    return (
      <div className="featured-panel__frame">
        <Slider {...settings}>
          <div className="slider-img-wrapper">
            <div className="featured-panel__slide-new-heading text-align-center">
              <h2 className="featured-panel__slide-new__title">For Survey Makers:</h2>
              <p className="featured-panel__slide-new__paragraph">Get real insights in real time using mobile surveys</p>
            </div>
            <img alt="Diy tool" className="slider-img slider-img--2" src={ImgSurveyMakers} />
          </div>
          <div className="slider-img-wrapper">
            <div className="featured-panel__slide-new-heading  text-align-center">
              <h2 className="featured-panel__slide-new__title">For App Creators:</h2>
              <p className="featured-panel__slide-new__paragraph">Monetize your app with mobile-optimized surveys</p>
            </div>
            <img alt="Take survey exapmle" className="slider-img slider-img--1" src={ImgAppCreators} />
          </div>
        </Slider>
      </div>
  )
  }
}

export default SlideBar;
