import React from 'react';
import PropTypes from 'prop-types';
import Slider from './slider';
import Logo from './Logo.js';
import SwitchAction from './switch_action.js';
import Copyright from './copyright';

export default class Layout extends React.Component {

  render() {
    //update page title
    document.title = `Pollfish - ${this.getTitle()}`;
    const isGuest = this.props.location.pathname.indexOf('login-guest') > -1;

    return (
      <div>
        <div className="panel-wrapper">
          <header className="panel-header">
            <Logo />
            {!isGuest && (
              <SwitchAction action={this.getPage()} {...this.props} />
            )}
          </header>
          <div className="main-panel">
            <div className="display-flex-column full-height flex-center">
              <div className="main-panel__content main-panel__content--login">
                { this.props.children }
              </div>
              <Copyright />
            </div>
          </div>
          { this.renderSidebar() }
        </div>
      </div>
    );
  }

  getPage() {
    return (this.props.location.pathname.indexOf('signup') > -1) ? 'signup' : 'login';
  }

  getTitle() {
    let title = '';
    if (this.getPage() === 'signup') {
      title += 'Register as a ';
      title += (this.props.params.userType === 'publisher') ? 'Publisher' : 'Researcher';
    } else {
      title += 'Sign in';
    }
    return title;
  }

  renderSidebar() {
    return <Slider params={this.props.params} />;
  };
};

Layout.propTypes = {
  children: PropTypes.object
};
