/*
 * OnboardingPage Messages
 *
 * This contains all the text for the OnboardingPage container.
 */
import { ONBOARDING_STEPS_VALUES } from './constants';


export default {
  [ONBOARDING_STEPS_VALUES.PASSWORD]: "Please enter a password",
  [ONBOARDING_STEPS_VALUES.FULL_NAME]: "What's your full name?",
  [ONBOARDING_STEPS_VALUES.COMPANY_NAME]: "What's your company's name?",
  [ONBOARDING_STEPS_VALUES.QUALIFICATION]: "I'd like to",
  [ONBOARDING_STEPS_VALUES.REFERRAL]: "How did you find us?",
  [ONBOARDING_STEPS_VALUES.LEGAL]: 'Legal',
  qualificationOptions: {
    createSurvey: "Buy survey responses",
    ownList: "Send a survey to my own list",
    getPaid: "Get paid to take surveys",
  },
  newAccount: 'New Account',
  fullNameRequired: 'Full name is required',
  qualificationRequired: 'Please select a reason',
  referralRequired: 'Please select a referral',
  next: 'Next',
  getPaidAction: 'Τake Paid Surveys with Swagbucks',
  gotIt: 'Ok, got it!',
  newsletterConsent: 'I want to receive important updates and offers from Pollfish',
  register: 'Register',
  accept: 'I accept',
  notAccept: 'I do not accept',
  onboardingHeading: 'Welcome onboard!',
  generalHeading: 'Tell us a bit about yourself',
  legalHeading: "Pollfish Terms Of Service & Privacy Policy",
  legalPrompt: "Please accept our terms to use Pollfish",
};
